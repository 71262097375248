import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Image, Input, Space, Button, message, Tag, Empty, Modal, Spin, Form, Select, DatePicker, Row, Col, Typography} from 'antd'
import moment from 'moment' 
import dayjs from 'dayjs'

import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Title } = Typography;
import { AppContext } from '../App';

export var fetchData = ''

const ListFanshineFeeHistory = (fanshineId) => {

  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  const [emptyBranch, setEmptyBranch] = useState(false)

  const [branchName,setBranchName] = useState('')
  const [form] = Form.useForm();
  const [totalRecord, setTotalRecord] = useState(0)

  const [openAddModal,setOpenAddModal] = useState(false)
  const [openDeleteModal,setOpenDeleteModal] = useState(false)
  const [openEditModal,setOpenEditModal] = useState(false)

  const fanshineSelect = fanshineId.fanshineId
  const [fanshineFeeSelect, setFanshineFeeSelect] = useState()

  const [messageConfirmDelete, setMessageConfirmDelete] = useState('ยืนยันการลบแฟรนไชส์')
  
  const columns = [
    {
        title: 'ลำดับ',
        width: 100,
        dataIndex: 'order',
        key: 'order',
        render: (text, record) => (
          <div>
            {text}
          </div>
        )    
    },
    {
      title: 'วันที่มีผล',
      width: 90,
      dataIndex: 'effectiveDate',
      key: 'effectiveDate',
      render: (text, record) => (
        <div>
            {moment(text).format('DD/MM/YYYY')}
        </div>
      ),
    },
    {
        title: 'ค่าบริการแฟรนไชส์',
        width: 80,
        dataIndex: 'fanshineFee',
        key: 'fanshineFee',
        render: (text, record) => (
          <div>
            {text}%
          </div>
        ),
    },

    {
      title: 'ค่าบริการแอปพลิเคชั่น',
      width: 90,
      dataIndex: 'applicationFee',
      key: 'applicationFee',
      render: (text, record) => (
        <div>
          {text}%
        </div>
      ),
    },
    {
      title: 'ค่าบริการสาขา',
      width: 90,
      dataIndex: 'branchFee',
      key: 'branchFee',
      render: (text, record) => (
        <div>
          {text}%
        </div>
      ),
    },
    {
      title: 'จัดการ',
      width: 90,
      dataIndex: 'id',
      key: 'action',
      render: (text, record) => (
        <div>
            { dayjs() < dayjs(record.effectiveDate) ? <Button onClick={ () => editFanshineShow(record.id, record.fanshineFee, record.applicationFee, record.branchFee, dayjs(record.effectiveDate))} type='primary'>แก้ไข</Button> : '' }
            { totalRecord > 1 && dayjs() < dayjs(record.effectiveDate) ? <Button onClick={() => deleteFanshineFeeShow(record.id)} danger>ลบ</Button> : '' }
        </div>
      ),
    }
  ]

  fetchData = async () => {
    setData('')
    try {
      const response = await axios.get(`${API_URL}getListFanshineFeeHistory/${fanshineSelect}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      const adjustedData = response.data.listFanshineFeeHistory.map((fanshine) => ({
        ...fanshine,
        key: fanshine.id,
      }));

      if(response.data.listFanshineFeeHistory.length === 0){
        setEmptyBranch(true)
      }else{
        setEmptyBranch(false)
      }

      setTotalRecord(response.data.listFanshineFeeHistory.length)

      if(response.data.branchName){
        setBranchName(response.data.branchName)
      }

      setData(adjustedData)
    } catch (error) {
      console.log('error', error)
    }
  }

    useEffect(() => { 
        fetchData(fanshineSelect)
    }, [])


    const createNewFanshieFeeShow = async function (){
      setOpenAddModal(true)
      form.setFieldsValue({
        fanshineId: fanshineSelect
      })
    }

    const addFanshineFee = async function (values){
      try {
        const response = await axios.post(`${API_URL}createFanshineFee`, {
            fanshineId: values.fanshineId,
            fanshineFee: values.fanshineFee,
            applicationFee: values.applicationFee,
            branchFee: values.branchFee,
            effectiveDate: values.effectiveDate
        }, {
            headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })

        if (response.status === 200) {
            messageApi.open({
                type: 'success',
                content: 'เพิ่มค่าบริการแฟรนไชส์เรียบร้อย',
            });
            fetchData()
        }
    } catch (error) {
        console.log(error);
    }
    }

    const editFanshineShow = async (fanshineFeeId, fanshineFee, applicationFee, branchFee, effectiveDate) => {
        
        form.setFieldsValue({
            fanshineFeeId: fanshineFeeId,
            fanshineFee: fanshineFee,
            applicationFee: applicationFee,
            branchFee: branchFee,
            effectiveDate: effectiveDate
        });

        setOpenEditModal(true)
    }

    const editFanshineFee = async (values) => {
        try {
            const response = await axios.post(`${API_URL}editFanshineFee`, {
                fanshineFeeId: values.fanshineFeeId,
                fanshineFee: values.fanshineFee,
                applicationFee: values.applicationFee,
                branchFee: values.branchFee,
                effectiveDate: values.effectiveDate
            }, {
                headers: {
                    'Authorization': `bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if (response.status === 200) {
                messageApi.open({
                    type: 'success',
                    content: 'แก้ไขค่าบริการแฟรนไชส์เรียบร้อย',
                });
                fetchData()
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteFanshineFeeShow = async (fanshineFeeId) => {
        setMessageConfirmDelete(`ยืนยันการลบค่าบริการแฟรนไชส์ ?`)
        setFanshineFeeSelect(fanshineFeeId)
        setOpenDeleteModal(true)
    }

    const deleteFanshineFee = async () => {
        try {
            const response = await axios.delete(`${API_URL}deleteFanshineFee/${fanshineFeeSelect}`, {
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })
      
            if(response.status === 200){
                messageApi.open({
                    type: 'success',
                    content: 'ลบค่าบริการแฟรนไชส์เรียบร้อย',
                });
                fetchData()
            }

          } catch (error) {
            console.log('error', error)
          }
    }

  return (
    <div className="fetch-event-table">
      {contextHolder}
      {
        <Row >
          <Col span={21}><Title level={5}>สาขา: {branchName}</Title></Col>
          <Col span={2}><Button type='primary' onClick={() => createNewFanshieFeeShow()}>เพิ่มค่าบริการใหม่</Button></Col>
        </Row>
      }
      {
        <Modal
        title="เพิ่มค่าบริการแฟรนไชส์"
        centered
        open={openAddModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    addFanshineFee(values)
                    form.resetFields();
                    setOpenAddModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenAddModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="fanshineId" hidden={true}>
                <Input type='hidden'/>
            </Form.Item>
            <Form.Item name="fanshineFee" label="ค่าบริการแฟรนไชส์ (%)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกค่าบริการแฟรนไชส์',
                    },
                ]}>
                <Input type='number' min={0} max={100} step={0.5}/>
            </Form.Item>
            <Form.Item name="applicationFee" label="ค่าบริการแอปพลิเคชั่น (%)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกค่าบริการแอปพลิเคชั่น',
                    },
                ]}>
                <Input type='number' min={0} max={100} step={0.5} />
            </Form.Item>
            <Form.Item name="branchFee" label="ค่าบริการสาขา (%)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกค่าบริการสาขา',
                    },
                ]}>
                <Input type='number' min={0} max={100} step={0.5} />
            </Form.Item>
            <Form.Item name="effectiveDate" label="วันที่เริ่มเก็บค่าบริการ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกวันที่เริ่มเก็บค่าบริการ',
                            },
                        ]}>
                <DatePicker/>
              </Form.Item>
        </Form>
        </Modal>
      }
      {
        <Modal
            title={
                <>
                    <ExclamationCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                    ลบค่าบริการแฟรนไชส์
                </>
            }
            centered
            open={openDeleteModal}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            onOk={() => {
                deleteFanshineFee()
                setOpenDeleteModal(false)
            }}
            onCancel={() => {
                setOpenDeleteModal(false);
            }}
            width={300}
        >
            {messageConfirmDelete}
        </Modal>
      }
      {
        <Modal
        title="แก้ไขค่าบริการ"
        centered
        open={openEditModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    editFanshineFee(values)
                    form.resetFields();
                    setOpenEditModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
            form.resetFields();
            setOpenEditModal(false);
        }}
        width={400}
    >
        <Form
            form={form}
            layout="vertical"
        >
            <Form.Item name="fanshineFeeId" hidden={true}>
                <Input type='hidden'/>
            </Form.Item>
            <Form.Item name="fanshineFee" label="ค่าบริการแฟรนไชส์ (%)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกค่าบริการแฟรนไชส์',
                    },
                ]}>
                <Input type='number' min={0} max={100} step={0.5}/>
            </Form.Item>
            <Form.Item name="applicationFee" label="ค่าบริการแอปพลิเคชั่น (%)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกค่าบริการแอปพลิเคชั่น',
                    },
                ]}>
                <Input type='number' min={0} max={100} step={0.5} />
            </Form.Item>
            <Form.Item name="branchFee" label="ค่าบริการสาขา (%)"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกค่าบริการสาขา',
                    },
                ]}>
                <Input type='number' min={0} max={100} step={0.5} />
            </Form.Item>
            <Form.Item name="effectiveDate" label="วันที่เริ่มเก็บค่าบริการ"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกวันที่เริ่มเก็บค่าบริการ',
                            },
                        ]}>
                <DatePicker/>
              </Form.Item>
        </Form>
        </Modal>
      }

      {data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: 400,
          }}
          rowKey="id"
        />
      ) : ( emptyBranch ? <p>ไม่พบข้อมูล</p> : <Spin />)
      }
    </div>
  )
}

export default ListFanshineFeeHistory