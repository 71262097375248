import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import moment from 'moment';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Card, Spin, Radio, Form, Select, Anchor} from 'antd'
import { AppContext } from '../App';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Colors,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  ChartDataLabels,
  ArcElement, 
  Tooltip, 
  Legend,
  Colors,
);

const IncomeThisWeek = (homeData) => {

    const token = localStorage.getItem('Authentication');
    const API_URL = process.env.REACT_APP_API_URL;

    const [maxValueOfChart, setMaxValueOfChart] = useState(100)
    const [loadingChart, setLoadingChart] = useState(true)
    const [chartReady, setChartReady] = useState(false)
    const [chartType, setChartType] = useState("week")
    const [averageIncome, setAverageIncome] = useState(5000)
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [branch, setBranch] = useState([]);
    const [branchSelected, setBranchSelected] = useState('all');

    const [listDateData,setListDateData] = useState([])
    const [listIncomeData,setListIncomeData] = useState([])

    const [form] = Form.useForm();

    // period change
     useEffect(() => {
      
      setChartType(homeData.chartType)
      setChartData({
        labels: [],
        datasets: [],
      })

      FetchIncomeAppByHour(homeData.chartType, branchSelected)

    },[homeData.chartType]);

    // branch change
    useEffect(() => {

      setBranchSelected(homeData.branchSelectedIndex)
      setLoadingChart(true)
      setChartReady(false)
      setChartData({
        labels: [],
        datasets: [],
      })
      FetchIncomeAppByHour(chartType,homeData.branchSelectedIndex)

  }, [homeData.branchSelectedIndex])

    const options = {
        plugins: {
          legend: false,
          datalabels: {
            anchor:'end',
            align:'end',
            color: '#000',
          }
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            max: maxValueOfChart
          },
        },
    };


   const FetchIncomeAppByHour = async (chartTypeData,branchId) => {

      if(!branchId){branchId = ""}

        try {
            const response = await axios.get(`${API_URL}incomeAppByHour/${chartTypeData}/${branchId}`, {
              headers: {
                'Authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
              }
            })

            const listDate = response.data.listHour
            const listIncome = response.data.listHourIncome
            var maxValueOfChart = parseInt(response.data.maxValueOfChart)

            if(maxValueOfChart > 10000){
              maxValueOfChart = maxValueOfChart + 5000
            }else if(maxValueOfChart > 1000){
              maxValueOfChart = maxValueOfChart + 1000
            }else{
              maxValueOfChart = maxValueOfChart + 100 
            } 


            setListDateData(listDate)
            setListIncomeData(listIncome)
            setMaxValueOfChart(maxValueOfChart)


            const data = {
                labels: listDate,
                datasets: [{
                  data: listIncome.map((x) => x.totalPercent ? x.totalIncome : null),
                  backgroundColor:["#FF1F57AA","#FF1F69AA","#FF1F7CAA","#FF1F8FAA","#FF1FA2AA","#FF1FB4AA","#FF1FC7AA","#FF1FDAAA","#FF1FECAA","#FF1FFFAA","#EC1FFFAA","#DA1FFFAA","#C71FFFAA","#B41FFFAA","#A11FFFAA","#8F1FFFAA","#7C1FFFAA","#691FFFAA","#571FFFAA","#441FFFAA","#311FFFAA","#1F1FFFAA","#1F31FFAA","#1F44FFAA"]
                }]
             };

            setChartData(data);

            setLoadingChart(false)
            setChartReady(true)

          } catch (error) {
            console.log('error', error)
          }
    }


    return (
        <Card title="รายได้ในแต่ละชั่วโมง" >
            <center> <Spin spinning={loadingChart} /></center>
            {chartReady && <Bar options={options} data={chartData}/> }
        </Card>
    )
}

export default IncomeThisWeek